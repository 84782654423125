import { useMemo } from "react";
import moment from "moment";

export const useFormattedDate = (dateObject) => {
  const formattedDate = useMemo(() => {
    let showDateField = moment().format("ddd, DD MMMM YYYY");

    console.log(dateObject)

    if (dateObject && dateObject.type !== "dateRange") {
      const dayOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      
      const days =
        dateObject.recurring?.days.map(
          (day) => day.charAt(0).toUpperCase() + day.slice(1)
        ) || [];

      // Sort days based on the predefined day order
      const sortedDays = days.sort(
        (a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b)
      );

      if (sortedDays.length === 1) {
        showDateField = sortedDays[0];
      } else if (sortedDays.length === 2) {
        showDateField = sortedDays.join(" and ");
      } else if (sortedDays.length === 7) {
        showDateField = "Daily";
      } else if (sortedDays.length > 2) {
        const lastDay = sortedDays.pop();
        showDateField = `${sortedDays.join(", ")}, and ${lastDay}`;
      }
    } else if (dateObject && dateObject.type === "dateRange") {
      const startDate = moment(dateObject.dateRange?.startDate).startOf("day");
      const endDate = moment(dateObject.dateRange?.endDate).startOf("day");

      if (dateObject.dateRange?.endDate) {
        if (startDate.isSame(endDate)) {
          showDateField = startDate.format("ddd, DD MMMM YYYY");
        } else {
          showDateField =
            dateObject.showEndDate === false
              ? startDate.format("ddd, DD MMMM YYYY")
              : `${startDate.format("Do MMM")} to ${endDate.format("Do MMM")}`;
        }
      } else {
        showDateField = moment().format("ddd, DD MMMM YYYY");
      }
    }

    return showDateField;
  }, [dateObject]);

  return formattedDate;
};
