import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { GetAllCategory } from '../../../../http';
import SocialMediaLink from '../../../../utils/SocialMediaLinks';
import SearchBox from './SearchBox';
const HeroSection = () => {

    const navigate = useNavigate()

    const [isVisible, setIsVisible] = useState(false);
    const [query, setQuery] = useState('')
    const [showLinks, setShowLinks] = useState(false)
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    function displayLinks() {
        console.log("clicked")
        setShowLinks(true)
    }

    const followButtonRef = useRef(null);

    // Add a click event listener to the entire document to handle clicks outside the button
    const handleClickOutside = (event) => {
        if (followButtonRef.current && !followButtonRef.current.contains(event.target)) {
            setShowLinks(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const fetchCategories = async () => {
            setLoading(true);
            try {
                const res = await GetAllCategory();
                setCategories(res.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching categories:', error);
                setLoading(false);
            }
        };

        fetchCategories();

        // Trigger the animation after a delay or based on your specific logic
        setTimeout(() => {
            setIsVisible(true);
        }, 1000); // Adjust the delay as needed
    }, []);

    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    return (
        <>
            {
                isStandalone || window.isNative == true
                    ?
                    <>
                    </>
                    :
                    <div
                        className="h-4/6 NoHeader bg-center bg-no-repeat bg-cover bg-[url('https://muscat-media.nyc3.cdn.digitaloceanspaces.com/IMAGE.png')]">
                        <header className='p-5 md:p-10 '>

                            <div className="">
                                <div
                                    className={` bottom-0 transition ease-in-out delay-0 duration-300 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
                                        }`}
                                >
                                    <div className='flex justify-center md: pt-0 md:p-10'>
                                        <div className='z-50 '>
                                            <p className='pb-5 leading-loose text-4xl md:text-5xl text-center text-white font-bold'>
                                                <h1 className={`ml-0 drop-shadow-xl font-bold text-shadow-lg font-garamond uppercase`}>Muscat Where To</h1>

                                                <h2 className='z-50 text-center text-xl md:text-3xl mb-2 ml-0 font-thin text-shadow-lg font-garamond tracking-wider'>
                                                    Latest Offers and Events in Muscat
                                                </h2>
                                            </p>

                                            <div className='mt-5 z-0 flex md:hidden items-center justify-center '>
                                                <div className='z-50 bg-white-600 backdrop-blur-md backdrop-greyscale-2 backdrop-opacity-80 border border-t-white border-l-white rounded-md w-11/12 md:w-2/3 flex items-center justify-center flex-col rounded-lg'>
                                                    <p className='z-50 w-full text-xl pt-2 font-semibold text-white mb-3 text-center'>
                                                        Where To ?
                                                    </p>

                                                    <div className="absolute -top-14 z-50">
                                                        <img
                                                            className=""
                                                            src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/xmas/santa.png"
                                                            alt=""
                                                        />
                                                    </div>

                                                    {/* <div className='absolute -top-16 right-0 z-0'>
                                                        <img className='h-24 w-24' src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/rightflag.png" alt="" />
                                                    </div>
                                                    <div className='absolute -top-16 left-0 z-0'>
                                                        <img className='h-24 w-24' src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/leftflag.png" alt="" />
                                                    </div> */}
                                                    <form onSubmit={(e) => {
                                                        e.preventDefault()
                                                        if (query.trim() !== '') {
                                                            navigate(`/Category/events%20&%20offers?search=${query}`);
                                                        }
                                                    }}>
                                                        <div className="p-3">
                                                            <div className="z-10 relative ">
                                                                <input
                                                                    onChange={((e) => setQuery(e.target.value))}
                                                                    type="search" id="location-search" className="bg-[#E7E7E7] block p-2.5 w-72 z-20 text-xs text-gray-500 font-normal rounded-r-lg rounded-l-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-[#C0A04C] focus:border-[#C0A04C] dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-[#C0A04C]" placeholder="Search on Muscat Where To..." required />

                                                                <button
                                                                    type="submit" className="absolute top-0 right-0 h-full p-2.5 text-sm font-medium text-white bg-[#C0A04C] rounded-r-lg border border-[#C0A04C] hover:bg-[#C0A04C]  focus:[#A48533] dark:bg-[#C0A04C] dark:hover:bg-[#C0A04C] dark:focus:ring-[#C0A04C]">
                                                                    <img className='h-5' src="/images/icons/home-search.svg" alt="" />
                                                                    <span className="sr-only">Search</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>


                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <div onClick={() => setShowLinks(!showLinks)} className='hidden md:block z-50 '>
                                        <button
                                            ref={followButtonRef}
                                            className='m-0 -right-10 absolute  text-white bg-[#C0A04C] hover:bg-[#A48533] text-sm font-semibold py-2 pl-7 pr-6 rounded-l-full'>
                                            Follow Us
                                        </button>
                                        {showLinks
                                            ?
                                            <div
                                                className='-right-10 absolute  px-3 bg-white rounded-l-full flex space-x-2 py-2'>
                                                <a target="_blank" rel="noopener noreferrer" href={`https://wa.me/${SocialMediaLink.whatsapp}`}>
                                                    <img className='h-7' src="/images/icons/wp-a.svg" alt="" />
                                                </a>
                                                <a target="_blank" rel="noopener noreferrer" href={SocialMediaLink.facebook}>
                                                    <img className='h-7' src="/images/icons/fb-a.svg" alt="" />
                                                </a>

                                                <a target="_blank" rel="noopener noreferrer" href={SocialMediaLink.instagram}>
                                                    <img className='h-7' src="/images/icons/ig-a.svg" alt="" />
                                                </a>
                                                <a target="_blank" rel="noopener noreferrer" href={`mailto:${SocialMediaLink.email}`}>
                                                    <img className='h-7' src="/images/icons/emal-a.svg" alt="" />
                                                </a>
                                            </div>
                                            :
                                            <>
                                            </>
                                        }
                                    </div>

                                    <SearchBox />

                                </div>
                            </div>
                        </header>


                    </div>
            }
        </>
    )
}

export default HeroSection