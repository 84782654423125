import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

const FeatureImage = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="flex justify-center items-center align-middle py-5">
      <section className="w-full md:w-full sm:mx-5 lg:w-10/12 md:mx-5 md:w-10/12 xl:w-[70%] 2xl:w-7/12">
        <Carousel autoPlay autoPlaySpeed={4000}  infinite responsive={responsive}>
          <Link to="/category/merrychristmas">
            <img
              className="hidden md:flex w-full"
              src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/xmas/xmas.png"
              alt=""
            />
            <img
              className="mx-auto flex md:hidden"
              src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/xmas/xmas-mobilel.png"
              alt=""
            />
          </Link>
          <Link to="/category/happynewyear">
            <img
              className="hidden md:flex w-full"
              src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/newyearbanner.png"
              alt=""
            />
            <img
              className="mx-auto flex md:hidden"
              src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/newyearbannermob.png"
              alt=""
            />
          </Link>
        </Carousel>
      </section>
    </section>
  );
};

export default FeatureImage;
